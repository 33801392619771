import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withQuoteWizard } from '../components/withQuoteWizard';
import SEO from '../components/SEO';
import ProjectLayout from '../sections/ProjectLayout/ProjectLayout';
import ProjectHighlight from '../sections/ProjectHighlight/ProjectHighlight';
import ProjectFeature from '../sections/ProjectFeature/ProjectFeature';
import ProjectTransformation from '../sections/ProjectTransformation/ProjectTransformation';
import SimilarProjects from '../sections/SimilarProjects/SimilarProjects';
import { getElements } from '../helpers/functions';
import { similarProjects } from '../helpers/consts';

//Images
import {StanleyDayParkImages, SingleProjectsIcons} from '../imageURLs';


const pageData = {
  heroImage: StanleyDayParkImages.hero,
  projectTitle: 'Stanley Day Park',
  projectSubtitle: "Brisbane City Council",
  serviceShortInfo: [
    {
      icon: SingleProjectsIcons.icon1,
      type: "Service",
      text: "Remediation"
    },
    {
      icon: SingleProjectsIcons.icon2,
      type: "Type",
      text: "Soccer Field"
    },
    {
      icon: SingleProjectsIcons.icon3,
      type: "Turf type",
      text: "Nullarbor Couch"
    },
    {
      icon: SingleProjectsIcons.icon4,
      type: "Handover",
      text: "September 2021"
    },
    {
      icon: SingleProjectsIcons.icon5,
      type: "Value",
      text: "$150k"
    },
  ],
  serviceDescription: 'A soccer field remediation completed with premium sports turf and established with a customised maintenance program.',
  serviceListItems: [
    {
      value: '7,000m2',
      text: 'of premium Nullarbor Couch turf'
    },
    {
      value: '627t',
      text: 'of imported top soil'
    },
    {
      value: '410lm',
      text: 'of site fencing'
    },
  ],
  featureImage: StanleyDayParkImages.feature,
  keyProcesses: [
    {
      title: "Site establishment",
      desc: "Field was fenced, surveys conducted and environmental and safety control plans were initiated under council protocol."
    },
    {
      title: "Ground preparation",
      desc: "Field was topmaked to 30mm for the removal of grass thatch and weed. Disposed of green waste material at approved landfill site."
    },
    {
      title: "Soil Amendments",
      desc: "Soil amendment products were blecevated at 150mm depth and incorporated in to the profile."
    },
    {
      title: "Irrigation",
      desc: "Audit and service of irrigation system which required the replacement of faulty sprinkler heads to ensure it is fully functional."
    },
    {
      title: "Final Levels",
      desc: "Importation and GPS laser grading of 630T sports blend top soil to achieve a +/- 10mm final level."
    },
    {
      title: "Turf Installation",
      desc: "7000m2 of premium Nullarbor Couch was installed, rolled then treated with OxaMax fertiliser and pre-emergent."
    },
    {
      title: "Maintenance",
      desc: "6 week intensive maintenance program consisting of mowing, top dressing, aerating, fertilising and pest and weed control including line marking."
    },
  ],
  featureTitle: 'Profile Cultivation for High Performance',
  featureHighlights: [
    "Topmaked with the GKB Combinator CB200",
    "Rotary hoed to 200mm depth",
    "Gypsum, lime and fertiliser amendments incorporated into profile",
    "Surface preparation and profile contouring using GPS guided technology and Lawless AgriGrade 2800 Laser Bucket",
  ],
  featureThumbnail: StanleyDayParkImages.thumb,
  transformationImages: [
    {
      imagePath: StanleyDayParkImages.slide1
    },
    {
      imagePath: StanleyDayParkImages.slide2
    },
  ],
  similarProjects: getElements(similarProjects, [1,2,3,4,5,6,7,9])
}

const StanleyDayParkPage = () => {
  return (
    <React.Fragment>
      <SEO
        title="Stanley Day Park - Twin View Turf - Producer of Australia’s Best Lawns"
        keywords={[
          `Turf care guides`,
          `care guides`,
          `Turf`,
          `Lawns`,
          `Best Lawns`,
          `Australia’s Best Lawns`,
          `lawn producer`,
        ]}
      />
      <ProjectLayout>
        <ProjectHighlight 
          heroImage={pageData?.heroImage} 
          projectTitle={pageData.projectTitle} 
          projectSubtitle={pageData.projectSubtitle} 
          serviceShortInfo={pageData.serviceShortInfo}
          serviceDescription={pageData.serviceDescription} 
          serviceListItems={pageData.serviceListItems}
         />
        <ProjectFeature 
          featureImage={pageData?.featureImage}
          keyProcesses={pageData?.keyProcesses} 
          featureTitle={pageData?.featureTitle}
          featureHighlights={pageData.featureHighlights} 
          featureCtaHref={'/surface-preparation'} 
          featureThumbnail={pageData?.featureThumbnail} />
        <ProjectTransformation 
          transformationSlider={pageData.transformationImages}
         />
        <SimilarProjects cards={pageData.similarProjects} />
      </ProjectLayout>
    </React.Fragment>
  )
}

export default withQuoteWizard(withStyles({ withTheme: true })(StanleyDayParkPage));